import {AppName} from '@hconnect/common/hproduce/types'
import type {FC, SVGProps} from 'react'
import React from 'react'

import {ReactComponent as CockpitLogo} from './IconCockpit.svg'
import {ReactComponent as CockpitLogoSmall} from './IconCockpitSmall.svg'
import {ReactComponent as HProduceLogo} from './IconHProduce.svg'
import {ReactComponent as MaintainLogo} from './IconMaintain.svg'
import {ReactComponent as PlannerLogo} from './IconPlanner.svg'
import {ReactComponent as PomLogo} from './IconPom.svg'
import {ReactComponent as SettingsLogo} from './IconSettings.svg'

type LogoMap = Record<AppName, FC<SVGProps<SVGSVGElement>>>

export const logos: LogoMap = {
  Cockpit: CockpitLogo,
  Maintain: MaintainLogo,
  Planner: PlannerLogo,
  easyPOM: PomLogo,
  HProduce: HProduceLogo,
  Settings: SettingsLogo
}

interface Props extends SVGProps<SVGSVGElement> {
  name: AppName
  size?: number // will be used as pixel for width and hight,
}

export const AppLogo: FC<Props> = ({name, size = 32, ...props}) => {
  const Logo = logos[name]
  const pixelSize = size + 'px'

  if (!name || !Logo) throw new Error('wrong app name')

  if (size < 32 && name === 'Cockpit') {
    return <CockpitLogoSmall width={pixelSize} height={pixelSize} viewBox="0 0 36 36" {...props} />
  }

  if (name === 'HProduce') {
    return <Logo viewBox="0 0 102 16" {...props} />
  }

  return <Logo width={pixelSize} height={pixelSize} viewBox="0 0 32 32" {...props} />
}
