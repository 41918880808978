import MoreVertIcon from '@mui/icons-material/MoreVert'
import PushPinIcon from '@mui/icons-material/PushPin'
import {Box, IconButton, Menu, MenuItem, TableCell, Tooltip} from '@mui/material'
import classNames from 'classnames'
import React, {RefObject, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {TableFilterType} from '../Filter/TableFilter.types'
import {SortLabel} from '../SortLabel'
import {useTableStyles} from '../styles'
import {TableColumnType} from '../types'

import {useStyles} from './ResponsiveTableHeadCell.styles'

type ResponsiveTableHeadCellProps = {
  column: TableColumnType
  columns: TableColumnType[]
  columnHeaderHeight?: number
  rowHeight?: number
  loading: boolean
  onSort: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, field: string) => void
  handlePinColumn: (
    column: TableColumnType,
    direction?: 'left' | 'right',
    unpinAll?: boolean
  ) => void
  tableCellRefs: RefObject<{ref: HTMLDivElement | null; field: string}[]>
  setScrollLabel: (label?: string) => void
  scrollBoundary: 'start' | 'end' | 'between' | undefined
  filterListInputValues?: TableFilterType[]
  setFilterListInputValues?: (filterListInputValues: TableFilterType[]) => void
  enablePinning: boolean
  isCollapsedContent: boolean
  scrollLabel?: string
  sortedBy?: string
  sortingOrder?: 'asc' | 'desc'
  columnsWidth?: {field: string; width: number}[]
  shouldDisplayScrollButtons?: boolean
  shouldDisplayLeftBoxShadow?: boolean
  shouldDisplayRightBoxShadow?: boolean
  isContentWrapped?: boolean
}

// eslint-disable-next-line complexity
export const ResponsiveTableHeadCell = ({
  column,
  columns,
  columnHeaderHeight,
  rowHeight,
  onSort,
  sortingOrder,
  sortedBy,
  loading,
  scrollLabel,
  setScrollLabel,
  handlePinColumn,
  tableCellRefs,
  columnsWidth,
  scrollBoundary,
  shouldDisplayScrollButtons,
  shouldDisplayLeftBoxShadow,
  shouldDisplayRightBoxShadow,
  filterListInputValues,
  setFilterListInputValues,
  enablePinning,
  isCollapsedContent,
  isContentWrapped
}: ResponsiveTableHeadCellProps) => {
  const {classes} = useTableStyles()
  const {classes: tableHeadCellClasses} = useStyles()

  const {t} = useTranslation()

  const {
    field,
    headerName,
    description,
    flex,
    width,
    sortable,
    renderHeader,
    headerAlign,
    headerClassName
  } = column

  const label: React.ReactNode | string = renderHeader || headerName

  const handlePin = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleAddFilter = (column: TableColumnType) => {
    const filter = {
      filterField: column.field,
      filterName: column.filterName || column.headerName,
      filterType: column.filterType,
      filterDropdownOptions: column.filterDropdownOptions,
      filterDatePickerRangeOptions: column.filterDatePickerRangeOptions && {
        validFrom: {
          filterField: column?.filterDatePickerRangeOptions?.validFromField || ''
        },
        validTo: {
          filterField: column?.filterDatePickerRangeOptions?.validToField || ''
        }
      },
      filterShowSearch: column.filterShowSearch,
      filterMultiSelect: column.filterMultiSelect,
      noSelectionDropdownLabel: column.noSelectionDropdownLabel,
      isLoadingFilterData: column.isLoadingFilterData
    }

    if (setFilterListInputValues && filterListInputValues !== undefined) {
      setFilterListInputValues([
        ...filterListInputValues,
        {
          filterField: filter?.filterField || '',
          filterName: filter?.filterName || '',
          value: '',
          filterType: filter?.filterType,
          filterDropdownOptions: filter?.filterDropdownOptions,
          isLoadingFilterData: filter?.isLoadingFilterData,
          filterDatePickerRangeOptions: filter.filterDatePickerRangeOptions && {
            validFrom: {
              filterField: filter?.filterDatePickerRangeOptions?.validFrom?.filterField || ''
            },
            validTo: {
              filterField: filter?.filterDatePickerRangeOptions?.validTo?.filterField || ''
            }
          },
          filterShowSearch: filter.filterShowSearch,
          filterMultiSelect: filter.filterMultiSelect,
          noSelectionDropdownLabel: filter.noSelectionDropdownLabel
        }
      ])
    }

    setAnchorEl(null)
  }

  const calculateLeftPosition = () => {
    if (shouldDisplayScrollButtons) {
      if (scrollBoundary !== 'start' && !isCollapsedContent) {
        return 34
      }

      if (scrollBoundary !== 'start' && isCollapsedContent) {
        return 96
      }
    }
    return isCollapsedContent ? 62 : 0
  }

  const leftPosition = calculateLeftPosition()

  return (
    <TableCell
      ref={(el: any) => {
        const currentRef = tableCellRefs?.current?.find((ref) => ref.field === column.field)
        if (currentRef) {
          currentRef.ref = el
        }
      }}
      key={field}
      component="div"
      id={`column-${field}`}
      data-test-id={`table-column-${field}`}
      aria-label={`${headerName} ${field}`}
      className={classNames(
        classes.headCell,
        headerClassName,
        column.pinDirection === 'left'
          ? shouldDisplayLeftBoxShadow
            ? scrollBoundary !== 'start'
              ? classes.tableRowRightShadow
              : undefined
            : undefined
          : shouldDisplayRightBoxShadow
            ? scrollBoundary !== 'end'
              ? classes.tableRowLeftShadow
              : undefined
            : undefined
      )}
      align={headerAlign}
      sortDirection={sortable ? sortingOrder : false}
      style={{
        position: column.pinDirection ? 'sticky' : undefined,
        right:
          column.pinDirection === 'right'
            ? columnsWidth?.find((width) => width.field === column.field)?.width
              ? columnsWidth?.find((width) => width.field === column.field)?.width
              : 0
            : undefined,
        left:
          column.pinDirection === 'left'
            ? columnsWidth?.find((width) => width.field === column.field)?.width
              ? columnsWidth?.find((width) => width.field === column.field)?.width === 0
                ? leftPosition
                : (columnsWidth.find((width) => width.field === column.field)?.width || 0) +
                  leftPosition
              : leftPosition
            : undefined,
        borderRight: shouldDisplayLeftBoxShadow
          ? scrollBoundary === 'start'
            ? '3px solid #EAEDF0'
            : undefined
          : undefined,
        borderLeft: shouldDisplayRightBoxShadow
          ? scrollBoundary === 'end'
            ? '3px solid #EAEDF0'
            : undefined
          : undefined,
        zIndex: column.pinDirection ? 3 : undefined,
        verticalAlign: isContentWrapped ? 'top' : 'center',
        ...(width ? {width} : {flexGrow: flex || 1, flexBasis: 0}),
        ...(columnHeaderHeight || rowHeight ? {height: columnHeaderHeight || rowHeight} : {})
      }}
    >
      <Box
        className={tableHeadCellClasses.columnHeader}
        style={{alignItems: isContentWrapped ? 'flex-start' : 'center'}}
      >
        {description && label ? (
          <Tooltip placement="top" title={description} enterDelay={100}>
            <span>
              <SortLabel
                field={field}
                sortedBy={sortedBy}
                sortable={sortable}
                sortingOrder={sortingOrder}
                onSort={onSort}
                label={label}
                scrollLabel={scrollLabel}
                setScrollLabel={setScrollLabel}
                loading={loading}
              />
            </span>
          </Tooltip>
        ) : label ? (
          <SortLabel
            field={field}
            sortedBy={sortedBy}
            sortable={sortable}
            sortingOrder={sortingOrder}
            onSort={onSort}
            label={label}
            scrollLabel={scrollLabel}
            setScrollLabel={setScrollLabel}
            loading={loading}
          />
        ) : null}
        {!column.noColumnSelection && enablePinning && (
          <Box style={{display: 'flex', alignItems: 'center', alignSelf: 'center'}}>
            {column.pinDirection && (
              <IconButton
                onClick={() => handlePinColumn(column, undefined)}
                className={tableHeadCellClasses.columnMenuButton}
              >
                <PushPinIcon style={{fill: 'currentcolor'}} />
              </IconButton>
            )}
            <IconButton
              onClick={(event) => handlePin(event)}
              className={
                open
                  ? classNames(tableHeadCellClasses.columnMenuButton, tableHeadCellClasses.noHover)
                  : tableHeadCellClasses.columnMenuButton
              }
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        )}
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {column.filterable && (
            <MenuItem
              disabled={
                !filterListInputValues ||
                !setFilterListInputValues ||
                !!filterListInputValues?.find((inputValue) =>
                  inputValue.filterDatePickerRangeOptions?.validFrom?.filterField ||
                  inputValue.filterDatePickerRangeOptions?.validTo?.filterField
                    ? inputValue.filterDatePickerRangeOptions?.validFrom?.filterField ===
                        column.filterDatePickerRangeOptions?.validFromField ||
                      inputValue.filterDatePickerRangeOptions?.validTo?.filterField ===
                        column.filterDatePickerRangeOptions?.validToField
                    : inputValue.filterField === column.field
                )
              }
              onClick={() => {
                handleAddFilter(column)
              }}
            >
              {t('tableMenu.addFilter')}
            </MenuItem>
          )}
          <MenuItem
            disabled={column.pinDirection === 'left'}
            onClick={() => {
              handlePinColumn(column, 'left')
              setAnchorEl(null)
            }}
          >
            {t('tableMenu.pinLeft')}
          </MenuItem>
          <MenuItem
            disabled={column.pinDirection === 'right'}
            onClick={() => {
              handlePinColumn(column, 'right')
              setAnchorEl(null)
            }}
          >
            {t('tableMenu.pinRight')}
          </MenuItem>
          <MenuItem
            disabled={column.pinDirection === undefined}
            onClick={() => {
              handlePinColumn(column, undefined)
              setAnchorEl(null)
            }}
          >
            {t('tableMenu.unpin')}
          </MenuItem>
          <MenuItem
            disabled={columns.every((value) => value.pinDirection === undefined)}
            onClick={() => {
              handlePinColumn(column, undefined, true)
              setAnchorEl(null)
            }}
          >
            {t('tableMenu.unpinAll')}
          </MenuItem>
        </Menu>
      </Box>
    </TableCell>
  )
}
