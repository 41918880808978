import DownloadIcon from '@mui/icons-material/Download'
import {Table, TableBody, TableContainer, Box, IconButton, TableRow, TableCell} from '@mui/material'
import classNames from 'classnames'
import React, {useEffect, useRef, useState} from 'react'

import {ColumnSelection} from './ColumnSelection/ColumnSelection'
import {TableFilter} from './Filter/TableFilter'
import {useColumnOrder} from './hooks/useColumnOrder'
import {useDetectBrowserZoom} from './hooks/useDetectBrowserZoom'
import {useScrollBoundaries} from './hooks/useScrollBoundaries'
import {useSelection} from './hooks/useSelection'
import {useStorePinnedColumns} from './hooks/useStorePinnedColumns'
import {ResponsiveTablePagination} from './ResponsiveTablePagination'
import {ScrollButton} from './ScrollButton/ScrollButton'
import {SkeletonRows} from './SkeletonRows'
import {useTableStyles} from './styles'
import {ResponsiveTableHead} from './TableHead/ReponsiveTableHead'
import {EmptyResponsiveTableRow} from './TableRow/EmptyResponsiveTableRow'
import {ResponsiveTableRow} from './TableRow/ResponsiveTableRow'
import {GridValueRowModel, KeyFieldType, ResponsiveTableProps, TableColumnType} from './types'

// eslint-disable-next-line complexity
export const ResponsiveTable = <R extends GridValueRowModel>({
  loading = false,
  columns,
  onSort,
  rows,
  sortingOrder,
  sortedBy,
  selectionEnabled = false,
  keyField,
  isRowSelectable,
  getSelectedRows,
  onRowClick,
  rowDetailsAvailable = false,
  rowDetailsClosed = false,
  emptyMessage,
  showEmptyMessage = true,
  mobileRowDetails,
  isMobile = false,
  rowHeight,
  rowSpacingProps,
  columnHeaderHeight,
  count,
  page,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  onFilterInputChange,
  filterListInputValues,
  setFilterListInputValues,
  onFilterRemove,
  enableHorizontalScroll = false,
  extraFilters,
  enableColumnSelection = false,
  storeFiltersOptions,
  tableHeight,
  stickyHeader = false,
  enablePinning = false,
  onExportData,
  collapsedContent,
  preselectedRows,
  promoBanner,
  promoBannerIndex,
  actionButtons,
  allowSelectRowsNotSelectedYet = true,
  rowOptions = undefined,
  scrollbarsVisible = false,
  tableContainerClassName
}: ResponsiveTableProps<R>) => {
  const {classes} = useTableStyles()
  const [columnOrder, setColumnOrder] = useState<TableColumnType[] | undefined>()

  const {storedPinnedColumns} = useStorePinnedColumns(columns, columnOrder, storeFiltersOptions)

  const {handlePinColumn, recalculateColumnsWidth, tableCellRefs} = useColumnOrder(
    columns,
    setColumnOrder,
    columnOrder
  )

  const [expandedIndice, setExpandedIndice] = useState<number | null>(null)

  const storedColumns = localStorage.getItem(
    `activeColumns-${storeFiltersOptions?.tableName}-${storeFiltersOptions?.userId}`
  )

  const [filteredColumns, setFilteredColumns] = useState(
    storedColumns
      ? columns.filter((column) => (JSON.parse(storedColumns) as string[]).includes(column.field))
      : columns.filter((column) => !column.noColumnSelection && !column.uncheckedByDefault)
  )
  const noHiddenColumnsNr =
    columns.filter((column) => !column.hide).length + (selectionEnabled ? 1 : 0)
  const [allRowsSelected, setAllRowsSelected] = useState<boolean>(false)
  const [selectedRowsInitialized, setSelectedRowsInitialized] = useState<boolean>(false)
  const [rowDetailsSelected, setRowDetailsSelected] = useState<KeyFieldType>()
  const [rowsSelected, setRowsSelected] = useSelection<R>(
    rows,
    allRowsSelected,
    setAllRowsSelected,
    keyField,
    isRowSelectable,
    preselectedRows?.initial ? undefined : preselectedRows?.rows
  )
  const [columnsWidth, setColumnsWidth] = useState<{field: string; width: number}[]>([])

  const [scrollLabel, setScrollLabel] = useState<string | undefined>()
  const deselectAllRows = () => {
    setAllRowsSelected(false)
    setRowsSelected(new Set<KeyFieldType>())
  }
  const isAllSelectedDisabled = loading || !rows?.length
  const [horizontalScroll, setHorizontalScroll] = useState(0)

  const scrollRef = useRef<HTMLDivElement>(null)

  const {scrollBoundary} = useScrollBoundaries(scrollRef, loading, filteredColumns, isMobile)

  const {enableScrollButtons} = useDetectBrowserZoom()
  const shouldDisplayPagination =
    typeof count !== 'undefined' &&
    typeof page !== 'undefined' &&
    typeof rowsPerPage !== 'undefined' &&
    typeof onChangePage !== 'undefined' &&
    typeof onChangeRowsPerPage !== 'undefined'

  const shouldDisplayFilters =
    typeof onFilterRemove !== 'undefined' &&
    typeof onFilterInputChange !== 'undefined' &&
    typeof filterListInputValues !== 'undefined' &&
    typeof setFilterListInputValues !== 'undefined'

  const shouldDisplayScrollButtons =
    !loading && scrollBoundary && !isMobile && enableHorizontalScroll && enableScrollButtons

  const handleScrollBoundaryBoxShadow = () => {
    if (enableHorizontalScroll && !isMobile) {
      if (scrollBoundary === 'end') {
        return 'inset 5px 5px 10px -1px rgba(0, 0, 0, 0.11)'
      }

      if (scrollBoundary === 'start') {
        return 'inset -5px 5px 10px -1px rgba(0, 0, 0, 0.11)'
      }

      if (scrollBoundary === 'between') {
        return 'inset 5px 5px 10px -1px rgba(0, 0, 0, 0.11), inset -5px 5px 10px -1px rgba(0, 0, 0, 0.11)'
      }
    }

    return 'none'
  }

  const storedOrder = () => {
    return storedPinnedColumns
      ? (
          JSON.parse(storedPinnedColumns) as {
            field: string
            pinDirection: 'left' | 'right' | undefined
          }[]
        ).map((value) => {
          const foundColumn = columns.find((val) => val.field === value.field)
          return {
            ...foundColumn,
            field: foundColumn?.field || 'expander',
            pinDirection: value.pinDirection
          }
        })
      : undefined
  }

  useEffect(() => {
    !columnOrder && enablePinning && setColumnOrder(storedOrder())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedPinnedColumns])

  useEffect(() => {
    if (getSelectedRows) {
      const selectedRows = rows.filter((row) => rowsSelected.has(row[keyField]))
      getSelectedRows(selectedRows)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsSelected])

  useEffect(() => {
    if (scrollRef?.current && !loading && enableHorizontalScroll && !scrollLabel) {
      scrollRef.current.scrollLeft = horizontalScroll
    }
    const finalColumnsWidth = recalculateColumnsWidth()

    !loading && columnsWidth && finalColumnsWidth && setColumnsWidth(finalColumnsWidth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  useEffect(() => {
    const finalColumnsWidth = recalculateColumnsWidth()
    finalColumnsWidth && columnsWidth && setColumnsWidth(finalColumnsWidth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnOrder])

  useEffect(() => {
    if (preselectedRows && preselectedRows.rows.length > 0 && !selectedRowsInitialized) {
      const rows = preselectedRows.rows.map((x) => x[keyField])
      setRowsSelected(new Set(rows))
      setSelectedRowsInitialized(true)
    }
  }, [preselectedRows, selectedRowsInitialized, setRowsSelected, keyField])

  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: isMobile ? 'end' : 'space-between',
          alignItems: 'center'
        }}
      >
        {shouldDisplayFilters && (
          <TableFilter
            isMobile={isMobile}
            onFilterInputChange={onFilterInputChange}
            filterListInputValues={filterListInputValues}
            setFilterListInputValues={setFilterListInputValues}
            onFilterRemove={onFilterRemove}
            columns={columns}
            extraFilters={extraFilters}
            loading={loading}
            storeFiltersOptions={storeFiltersOptions}
          />
        )}
        {
          <Box style={{display: 'flex', alignItems: 'center', marginBottom: '10px', gap: '8px'}}>
            {actionButtons?.map((actionButton) => (
              <IconButton
                key={actionButton.name}
                onClick={actionButton.onClick}
                style={{boxShadow: 'none'}}
                data-test-id={`table-${actionButton.name}`}
              >
                {actionButton.icon}
              </IconButton>
            ))}
            {!isMobile && onExportData && (
              <IconButton
                onClick={() => onExportData()}
                style={{boxShadow: 'none'}}
                data-test-id="table-export-button"
              >
                <DownloadIcon style={{color: '#016AD4'}} />
              </IconButton>
            )}
            {!isMobile && enableColumnSelection && (
              <ColumnSelection
                columns={columns.filter((column) => !column.noColumnSelection)}
                filteredColumns={filteredColumns}
                setFilteredColumns={setFilteredColumns}
                storeFiltersOptions={storeFiltersOptions}
                loading={loading}
              />
            )}
          </Box>
        }
      </Box>
      <Box style={{display: 'flex'}}>
        {scrollBoundary !== 'start' && shouldDisplayScrollButtons && (
          <ScrollButton tableHeight={tableHeight} scrollRef={scrollRef} direction={'left'} />
        )}
        <TableContainer
          style={{
            display: 'flex',
            height: tableHeight || 'auto',
            width: '100%',
            boxShadow: handleScrollBoundaryBoxShadow(),
            overflowX: enableHorizontalScroll ? 'auto' : 'hidden',
            marginTop: filterListInputValues && filterListInputValues?.length > 0 ? '12px' : 0
          }}
          id="table-scroller"
          className={classNames(tableContainerClassName, {
            [classes.noVerticalScrollbar]: !scrollbarsVisible
          })}
          ref={scrollRef}
          onScroll={(event) => {
            if (!loading) {
              setHorizontalScroll(event.currentTarget.scrollLeft)
            }
          }}
        >
          <Table
            component="div"
            className={classes.root}
            data-test-id="table"
            stickyHeader={stickyHeader}
          >
            {!isMobile && (
              <ResponsiveTableHead
                selectionEnabled={selectionEnabled}
                allRowsSelected={allRowsSelected}
                isAllSelectedDisabled={isAllSelectedDisabled}
                columns={columnOrder || columns}
                deselectAllRows={deselectAllRows}
                filteredColumns={filteredColumns}
                handlePinColumn={handlePinColumn}
                loading={loading}
                onSort={onSort}
                rows={rows}
                setAllRowsSelected={setAllRowsSelected}
                setScrollLabel={setScrollLabel}
                scrollLabel={scrollLabel}
                columnHeaderHeight={columnHeaderHeight}
                rowHeight={rowHeight}
                rowsSelected={rowsSelected}
                sortedBy={sortedBy}
                sortingOrder={sortingOrder}
                columnsWidth={columnsWidth}
                tableCellRefs={tableCellRefs}
                scrollBoundary={scrollBoundary}
                shouldDisplayScrollButtons={shouldDisplayScrollButtons}
                filterListInputValues={filterListInputValues}
                setFilterListInputValues={setFilterListInputValues}
                enablePinning={enablePinning}
                isCollapsedContent={!!collapsedContent}
                isMobile={isMobile}
              />
            )}
            <TableBody component="div" style={{position: 'relative'}}>
              {!rows?.length && emptyMessage && showEmptyMessage ? (
                <EmptyResponsiveTableRow
                  emptyMessage={emptyMessage}
                  isMobile={isMobile}
                  enableHorizontalScroll={enableHorizontalScroll}
                  noHiddenColumnsNr={noHiddenColumnsNr}
                />
              ) : null}
              {loading ? (
                <SkeletonRows
                  rowsNo={3}
                  columnsNo={noHiddenColumnsNr}
                  isMobile={isMobile}
                  rowHeight={rowHeight}
                />
              ) : (
                rows.map((row, rowIndex) => (
                  <React.Fragment key={rowIndex}>
                    <ResponsiveTableRow
                      key={rowIndex}
                      keyField={keyField}
                      selectionEnabled={selectionEnabled}
                      isRowSelectable={isRowSelectable}
                      isMobile={isMobile}
                      collapsedContent={collapsedContent}
                      row={row}
                      rowIndex={rowIndex}
                      rowHeight={rowHeight}
                      columns={columns}
                      columnsWidth={columnsWidth}
                      scrollBoundary={scrollBoundary}
                      shouldDisplayScrollButtons={shouldDisplayScrollButtons}
                      onRowClick={onRowClick}
                      rowsSelected={rowsSelected}
                      setRowsSelected={setRowsSelected}
                      mobileRowDetails={mobileRowDetails}
                      columnOrder={columnOrder}
                      filteredColumns={filteredColumns}
                      rowDetailsSelected={rowDetailsSelected}
                      setRowDetailsSelected={setRowDetailsSelected}
                      rowDetailsAvailable={rowDetailsAvailable}
                      rowDetailsClosed={rowDetailsClosed}
                      expandedIndice={expandedIndice}
                      setExpandedIndice={setExpandedIndice}
                      allowSelectNotSelectedYet={allowSelectRowsNotSelectedYet}
                      rowOptions={rowOptions}
                      rowSpacingProps={rowSpacingProps}
                    />
                    {promoBanner &&
                      (rows.length === 1 ||
                        rowIndex === (promoBannerIndex !== undefined ? promoBannerIndex : 1)) && (
                        <TableRow component="div">
                          <TableCell colSpan={columns.length + 1} sx={{p: 0, border: 0}}>
                            {promoBanner}
                          </TableCell>
                        </TableRow>
                      )}
                  </React.Fragment>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {shouldDisplayScrollButtons && scrollBoundary !== 'end' && (
          <ScrollButton tableHeight={tableHeight} scrollRef={scrollRef} direction={'right'} />
        )}
      </Box>
      {shouldDisplayPagination && (
        <ResponsiveTablePagination
          count={count}
          page={page}
          onPageChange={onChangePage}
          SelectProps={{
            className: classes.tablePaginationSelect
          }}
          nextIconButtonProps={{className: classes.tablePaginationActionIconButton}}
          backIconButtonProps={{className: classes.tablePaginationActionIconButton}}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onChangeRowsPerPage}
          classes={{
            toolbar: classes.tablePaginationToolbar,
            displayedRows: classes.tablePaginationDisplayedRows,
            spacer: classes.tablePaginationSpacer,
            actions: classes.tablePaginationActions
          }}
        />
      )}
    </>
  )
}
