import {Typography} from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import {Box, IconButton, TextField} from '@mui/material'
import classNames from 'classnames'
import moment from 'moment/moment'
import React, {useState} from 'react'

import {DatePicker, SelectDropdown, SingleDatePicker} from '../../../FilterBar'
import {TableFilterTypeProps} from '../TableFilter.enum'
import {TableFilterDropdownProps} from '../TableFilter.types'

import {useStyles} from './TableFilterItem.styles'
import {LookupFilterProps} from './TableFilterItem.types'

export const TableFilterItem: React.FC<LookupFilterProps> = ({
  className: classNameProp,
  placeholder,
  inputValue,
  label,
  onInputChange,
  onClose,
  hasError,
  'data-test-id': dataTestId,
  filter,
  ...otherProps
}) => {
  const {classes} = useStyles()
  const [selectedItem, setSelectedItem] = useState<
    TableFilterDropdownProps | TableFilterDropdownProps[] | undefined
  >(filter.filterDropdownOptions?.find((option) => option.value === inputValue))
  const handleSelectDropdownOption = (item: TableFilterDropdownProps) => {
    if (filter.filterMultiSelect) {
      const currentVal = Array.isArray(selectedItem) ? [...selectedItem] : []
      const index = currentVal.findIndex(
        (items) => items.label === item.label && items.value === item.value
      )
      if (index === -1) {
        currentVal.push(item)
      } else {
        currentVal.splice(index, 1)
      }
      setSelectedItem(currentVal)
      onInputChange({
        value: currentVal.map((val) => val.value).join(', '),
        filterField: filter.filterField
      })
    } else {
      setSelectedItem(item)
      onInputChange({value: item?.value || '', filterField: filter.filterField})
    }
  }

  const handleFilterType = (filterType?: TableFilterTypeProps) => {
    switch (filterType) {
      case TableFilterTypeProps.DATEPICKER: {
        return (
          <>
            <SingleDatePicker
              data-test-id="table-filter-datepicker"
              noQuickSelection
              label={label}
              handleDateChange={({startDate}) => {
                startDate &&
                  onInputChange({
                    value: startDate?.format('YYYY-MM-DD'),
                    filterField: filter.filterField
                  })
              }}
              date={filter.value ? moment(filter.value) : null}
            />
            <div className={classes.closeRoot}>
              <IconButton
                data-test-id={`table-filter-close-${filter.filterField}`}
                className={classNames(classes.icon, classes.close)}
                onClick={() => onClose(filter)}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </>
        )
      }

      case TableFilterTypeProps.DATEPICKER_RANGE: {
        return (
          <>
            <DatePicker
              data-test-id="table-filter-datepicker"
              noQuickSelection
              label={label}
              handleDateChange={({startDate, endDate}) => {
                if (startDate && endDate) {
                  onInputChange({
                    filterDatePickerRangeOptions: {
                      validFrom: {
                        value: startDate?.format('YYYY-MM-DD'),
                        filterField: filter.filterDatePickerRangeOptions?.validFrom?.filterField
                      },
                      validTo: {
                        value: endDate?.format('YYYY-MM-DD'),
                        filterField: filter.filterDatePickerRangeOptions?.validTo?.filterField
                      }
                    }
                  })
                }
              }}
              {...(filter.filterDatePickerRangeOptions?.validFrom?.value &&
                filter.filterDatePickerRangeOptions?.validTo?.value && {
                  startDate: moment(filter.filterDatePickerRangeOptions?.validFrom?.value),
                  endDate: moment(filter.filterDatePickerRangeOptions?.validTo?.value)
                })}
            />
            <div className={classes.closeRoot}>
              <IconButton
                data-test-id={`table-filter-close-${filter.filterField}`}
                className={classNames(classes.icon, classes.close)}
                onClick={() => onClose(filter)}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </>
        )
      }

      case TableFilterTypeProps.DROPDOWN: {
        return (
          <Box style={{display: 'flex', width: '100%', alignItems: 'center'}}>
            <SelectDropdown
              data-test-id={`table-filter-dropdown-${filter.filterField}`}
              label={label || ''}
              options={filter.filterDropdownOptions}
              loading={filter.isLoadingFilterData}
              renderItem={(item) => (
                <Typography variant="body1" color="textSecondary">
                  {item.label}
                </Typography>
              )}
              noSelectionLabel={filter.noSelectionDropdownLabel}
              selectedItem={selectedItem}
              showSearch={filter.filterShowSearch}
              multiSelect={filter.filterMultiSelect}
              stringifyItem={(item) => `${item.label}-${item.value}`}
              onChange={(item) => handleSelectDropdownOption(item)}
              className={classes.dropdown}
              renderChosenItem={(items: TableFilterDropdownProps | TableFilterDropdownProps[]) => {
                if (Array.isArray(items)) {
                  return items.map((item) => item.label).join(', ')
                } else {
                  return items.label
                }
              }}
            />
            <div className={classes.closeRoot}>
              <IconButton
                data-test-id={`table-filter-close-${filter.filterField}`}
                className={classNames(classes.icon, classes.close)}
                onClick={() => onClose(filter)}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </Box>
        )
      }

      default: {
        return (
          <>
            <TextField
              id="filter-value"
              variant="standard"
              InputProps={{disableUnderline: true}}
              className={classes.textfield}
              label={label}
              value={inputValue}
              defaultValue={placeholder}
              margin="normal"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange({value: event.target.value, filterField: filter.filterField})
              }
              data-test-id={dataTestId ? `${dataTestId}-input` : 'filter-input'}
            />
            <div className={classes.closeRoot}>
              <IconButton
                data-test-id={`table-filter-close-${filter.filterField}`}
                className={classNames(classes.icon, classes.close)}
                onClick={() => onClose(filter)}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </>
        )
      }
    }
  }

  return (
    <Box
      className={classNames(classes.root, classNameProp, hasError && classes.error)}
      {...otherProps}
    >
      {handleFilterType(filter.filterType)}
    </Box>
  )
}
