import {
  alpha,
  Box,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Popper,
  TextField
} from '@material-ui/core'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import SearchIcon from '@material-ui/icons/Search'
import classNames from 'classnames'
import React from 'react'

import {HCThemeType} from '../../HCTheme.types'
import Divider from '../Divider'
import Typography from '../Typography'
import CloseIcon from '@material-ui/icons/Close'

const MAX_WIDTH = 35 // (35 x 8px = 280px)
const MAX_HEIGHT = 38

// FIXME: consolidate and simplify css
const useStyle = makeStyles((theme: HCThemeType) => ({
  root: {
    minWidth: '220px',
    height: '100%',
    // FIXME: do not use [50], use predefined color
    background: theme.palette.grey[50],
    // TODO: should be #e7e7e7 but is not part of theme, yet. gray-colors are calculated.
    //  maybe UX can change these to #e5e5e5 which would match "theme.palette.grey[100]"
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 4,
    '&:hover': {
      // TODO: missing hover colors
      background: theme.palette.grey[100],
      borderColor: theme.palette.grey[200]
    },
    '&>*': {
      borderLeft: `1px solid ${theme.palette.grey[100]}`,
      '&:hover': {
        borderLeftColor: theme.palette.grey[200]
      }
    },
    // FIXME: use standardized typography from HCTheme
    '& input': {
      fontSize: 16,
      color: alpha(theme.palette.secondary.dark, 0.96),
      fontWeight: 500,
      lineHeight: '19px',
      marginTop: 6
    }
  },
  rootDark: {
    minWidth: '220px',
    height: '100%',
    // FIXME: use standardized typography from HCTheme
    '& input': {
      fontSize: 16,
      color: theme.palette.text.secondary,
      fontWeight: 500,
      lineHeight: '19px',
      marginTop: 6
    },
    borderBottom: `2px solid ${theme.palette.grey[100]}`
  },

  textfield: {
    margin: 0,
    '& .MuiInputLabel-formControl': {
      top: theme.spacing(),
      left: theme.spacing(2.5),
      color: alpha(theme.palette.secondary.dark, 0.6),
      // FIXME: use standardized typography from HCTheme
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '14px',
      transform: 'none'
    },
    '& .MuiInputBase-root': {
      marginLeft: theme.spacing(2.5),
      marginTop: theme.spacing(2.25),
      marginBottom: theme.spacing(1),
      fontSize: 16,
      alignItems: 'flex-end',
      '& .MuiInputBase-input': {
        padding: 0
      }
    },
    '& .MuiInput-underline, & .MuiInput-underline:hover': {
      '&::after,&::before': {
        border: 'none'
      }
    }
  },
  textFieldDark: {
    margin: 0,
    '& .MuiInputLabel-formControl': {
      top: theme.spacing(),
      color: theme.palette.grey[100],
      // FIXME: use standardized typography from HCTheme
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '14px',
      transform: 'none'
    },
    '& .MuiInputBase-root': {
      marginTop: theme.spacing(2.25),
      marginBottom: theme.spacing(1),
      fontSize: 16,
      alignItems: 'flex-end',
      '& .MuiInputBase-input': {
        padding: 0
      }
    },
    '& .MuiInput-underline, & .MuiInput-underline:hover': {
      '&::after,&::before': {
        border: 'none'
      }
    }
  },
  icon: {
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      background: 'transparent'
    }
  },
  iconDark: {
    color: theme.palette.text.secondary,
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      background: 'transparent'
    }
  },
  select: {
    marginTop: theme.spacing(2),
    color: theme.palette.secondary.dark,
    '&>svg': {
      color: theme.palette.secondary.dark
    },
    '&:after,&:before': {
      borderBottom: ['none', '!important']
    },
    '&:focus,&:active': {
      backgroundColor: ['transparent', '!important']
    }
  },
  searchIcon: {
    padding: 0
  },
  resetRoot: {
    display: 'inline-block'
  },
  reset: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem'
    }
  },
  itemOnLight: {
    display: 'flex',
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    minHeight: '44px',
    '&:hover': {
      backgroundColor: '#e7e7e7'
    },
    '& p': {
      color: theme.palette.text.primarySoft
    },
    whiteSpace: 'normal',
    width: '100%'
  },
  selectedOnLight: {
    backgroundColor: '#f0f0f0 !important',
    '& span': {border: 'none'},
    '& div': {border: 'none'},
    '&:hover': {
      backgroundColor: ['#f0f0f0', '!important']
    }
  },
  popoverOnLight: {
    backgroundColor: '#f7f7f7',
    color: 'rgb(0, 55, 77)',
    marginTop: theme.spacing(2),
    width: theme.spacing(MAX_WIDTH),
    maxHeight: theme.spacing(MAX_HEIGHT),
    overflowY: 'scroll',
    zIndex: 1000
  }
}))

interface LookupSimpleFilterProps {
  placeholder?: string
  label: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: () => void
  onReset: () => void
  className?: any
  inputProps?: any
  loading?: boolean
  items?: any[]
  itemId?: string
  selectedItem?: any
  renderItem?: (item: any) => React.ReactNode
  onChangeDropdown?: (item: any) => void
  customerSearchByName?: boolean
  searchAgain?: (searchAgain: string) => any
  id?: string
  dark?: boolean
}

export const LookupSimpleFilter: React.FC<LookupSimpleFilterProps> = ({
  className: classNameProp,
  inputProps,
  placeholder,
  label,
  value,
  onChange = () => {
    /* default */
  },
  onSubmit = () => {
    /* default */
  },
  onReset,
  loading,
  items,
  itemId,
  selectedItem,
  renderItem,
  onChangeDropdown,
  customerSearchByName = false,
  searchAgain,
  id,
  dark = false,
  ...otherProps
}) => {
  const classes = useStyle()
  const [toggleDropdown, setToggleDropdown] = React.useState<boolean>(
    Boolean(items && items.length > 1)
  )
  const anchorEl = React.useRef<HTMLInputElement | null>(null)

  React.useEffect(() => {
    if (!loading && items && items.length > 1) {
      setToggleDropdown(true)
    }
  }, [loading])

  const popoverEnabled = !loading && items && items.length > 1

  const toggle = React.useCallback(() => {
    setToggleDropdown((value) => !value)
  }, [items])

  const handleReset = () => {
    onReset()
    if (anchorEl.current) {
      anchorEl.current.focus()
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      className={classNames(dark ? classes.rootDark : classes.root)}
      {...otherProps}
    >
      <TextField
        id={id}
        className={classNames(dark ? classes.textFieldDark : classes.textfield, classNameProp)}
        label={label}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        inputRef={anchorEl}
        autoFocus={true}
        {...(customerSearchByName
          ? {
              onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => {
                const {key} = event
                if (key === 'Backspace' || key === 'Delete') {
                  searchAgain && searchAgain(value)
                }
              }
            }
          : {})}
        onKeyPress={(event: React.KeyboardEvent) => {
          if (event.key === 'Enter') {
            onSubmit()
          }
        }}
        margin="normal"
      />
      {popoverEnabled ? (
        <IconButton
          onClick={toggle}
          className={classNames(dark ? classes.iconDark : classes.icon, classes.reset)}
        >
          <KeyboardArrowDown data-test-id="lookup-dropdown-toggle" />
        </IconButton>
      ) : null}
      <div className={classes.resetRoot}>
        <IconButton
          className={classNames(dark ? classes.iconDark : classes.icon, classes.reset)}
          onClick={onSubmit}
          data-test-id="lookup-simple-button"
        >
          <SearchIcon />
        </IconButton>
      </div>
      <div className={classes.resetRoot}>
        <IconButton
          className={classNames(dark ? classes.iconDark : classes.icon, classes.reset)}
          onClick={handleReset}
        >
          <CloseIcon />
        </IconButton>
      </div>
      {popoverEnabled && anchorEl.current ? (
        <Popper
          open={toggleDropdown}
          anchorEl={anchorEl.current}
          placement="bottom-start"
          className={classes.popoverOnLight}
        >
          <MenuList>
            {items?.map((item, index) => {
              return (
                <div key={itemId ? item[itemId] : `lookup-dropdown-item-${index}`}>
                  <MenuItem
                    classes={{
                      root: classes.itemOnLight,
                      selected: classes.selectedOnLight
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      setToggleDropdown(false)
                      !!onChangeDropdown && onChangeDropdown(item)
                    }}
                    selected={selectedItem === item}
                    data-test-id={`lookup-dropdown-item-${itemId ? item[itemId] : index}`}
                  >
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      component="span"
                      data-test-id="lookup-dropdown-text"
                    >
                      {renderItem && renderItem(item)}
                    </Typography>
                  </MenuItem>
                  <Divider color="onLight" />
                </div>
              )
            })}
          </MenuList>
        </Popper>
      ) : null}
    </Box>
  )
}
